import React, { useEffect, useState } from "react";
import { Menu, Skeleton } from "antd";
import { Link } from "react-router-dom";
import "../../containers/Auth.css";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import Item from "antd/lib/list/Item";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({
  sidebarCollapsed,
  setSidebarCollapsed,
  garageList,
  valueFeched,
  fetchGarageList,
}) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  const [acccChange, setAccChange] = useState(true);
  const tab1 = localStorage.getItem("tab1");
  const tab2 = localStorage.getItem("tab2");
  const location = useLocation();
  const [data, setData] = useState([]);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  console.log(pathname);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  console.log(defaultOpenKeys);

  const editPageRegex = /^\/admin\/vecrep\/editpage\/[a-zA-Z0-9]+$/; // Regex pattern for /admin/vecrep/editpage/:id
  const a =
    location.pathname === "/admin/vecrep/page" ||
    editPageRegex.test(location.pathname);
  console.log(a);
  const editPageRegex1 = /^\/admin\/vecrep\/editpagenear\/[a-zA-Z0-9]+$/; // Regex pattern for /admin/vecrep/editpage/:id
  const b =
    location.pathname === "/admin/vecrep/nearme" ||
    editPageRegex1.test(location.pathname);
  console.log(b);

  const filteredDataVecrep = garageList?.features?.filter(
    (item) => item.source === "vecrep"
  );
  const filteredDataVrgarage = garageList?.features?.filter(
    (item) => item.source === "vrgarage"
  );

  console.log(garageList);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch data based on tab1 value
    const fetchFilteredData = () => {
      if (tab1 === "1") {
        const filteredDataVrgarage = garageList?.features?.filter(
          (item) => item.source === "vrgarage"
        );
        setFilteredData(filteredDataVrgarage);
      } else if (tab1 === "2") {
        const filteredDataVecrep = garageList?.features?.filter(
          (item) => item.source === "vecrep"
        );
        setFilteredData(filteredDataVecrep);
      }
    };

    // Call fetchFilteredData when garageList or tab1 changes
    fetchFilteredData();
  }, [garageList, tab1]);

  // Then use filteredData in your component instead of data

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
        account={setAccChange}
        fetchGarageList={fetchGarageList}
      />
      <div className='gx-sidebar-content'>
        <CustomScrollbars className='gx-layout-sider-scrollbar'>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode='inline'>
            <MenuItemGroup key='main' className='gx-menu-group'>
              {tab1 === "1" && (
                <>
                  {garageList.role === "admin" && (
                    <>
                      <Menu.Item key='admin/dashboard'>
                        <Link to='/admin/dashboard'>
                          <i className='icon icon-dasbhoard' />
                          <span className='cliked'>
                            <IntlMessages id='Dashboard' />
                          </span>
                        </Link>
                      </Menu.Item>

                      <Menu.Item key='admin/GarageManagement'>
                        <Link to='/admin/GarageManagement'>
                          <i className='icon icon-crm' />
                          <span>
                            <span>
                              <IntlMessages id='Garage Management' />
                            </span>
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/ApproveGarage'>
                        <Link to='/admin/ApproveGarage'>
                          <i className='icon icon-crm' />
                          <span>
                            <span>
                              <IntlMessages id='Approve Garage' />
                            </span>
                          </span>
                        </Link>
                      </Menu.Item>

                      {/* <Menu.Item key="admin/customer-management">
                    <Link to="/admin/customer-management">
                      <i className="icon icon-calendar" />
                      <span>
                        <IntlMessages id="Customer Management" />
                      </span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="admin/notifications">
                    <Link to="/admin/notifications">
                      <i className="icon icon-calendar" />
                      <span>
                        <IntlMessages id="Notification List" />
                      </span>
                    </Link>
                  </Menu.Item> */}

                      <SubMenu
                        key='Subadmins'
                        popupClassName={getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            {" "}
                            <i className='icon icon-dasbhoard' />
                            <span>
                              <IntlMessages id='SubAdmin Management' />
                            </span>
                          </span>
                        }>
                        <Menu.Item key='admin/subAdminManagement'>
                          <Link to='/admin/subAdminManagement'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='SubAdmin-list' />
                            </span>
                          </Link>
                        </Menu.Item>

                        <Menu.Item key='admin/roles'>
                          <Link to='/admin/roles'>
                            <i className='icon icon-crypto' />
                            <span>
                              <IntlMessages id='Roles' />
                            </span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                      <Menu.Item key='admin/features'>
                        <Link to='/admin/features'>
                          <i className='icon icon-crypto' />
                          <span>
                            <IntlMessages id='Features' />
                          </span>
                        </Link>
                      </Menu.Item>

                      <Menu.Item key='admin/Promocodes'>
                        <Link to='/admin/Promocodes'>
                          <i className='icon icon-auth-screen' />
                          <span>
                            <IntlMessages id='PromoCode' />
                          </span>
                        </Link>
                      </Menu.Item>

                      <SubMenu
                        key='admin/SaleInvoice'
                        popupClassName={getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            {" "}
                            <i className='icon icon-dasbhoard' />
                            <span>
                              <IntlMessages id={`Analytics`} />
                            </span>
                          </span>
                        }>
                        <Menu.Item key='admin/SaleInvoice'>
                          <Link to='/admin/SaleInvoice'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Sale Invoice' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Purchase'>
                          <Link to='/admin/Purchase'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Purchase Invoice' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/JobCard'>
                          <Link to='/admin/JobCard'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='JobCard' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Vendors'>
                          <Link to='/admin/Vendors'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Vendors' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Inventory'>
                          <Link to='/admin/Inventory'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Inventory' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Subscription'>
                          <Link to='/admin/Subscription'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Subscription' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Rewards'>
                          <Link to='/admin/Rewards'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Cashback Coins' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Ratings'>
                          <Link to='/admin/Ratings'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Ratings & Reviews' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/Pushnotifications'>
                          <Link to='/admin/Pushnotifications'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='Push Nofications' />
                            </span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key='admin/xlsTracking'>
                          <Link to='/admin/xlsTracking'>
                            <i className='icon icon-auth-screen' />
                            <span>
                              <IntlMessages id='xls tracking' />
                            </span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                      <SubMenu
                        key='Categories'
                        popupClassName={getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            {" "}
                            <i className='icon icon-dasbhoard' />
                            <span>
                              <IntlMessages id='Category Management' />
                            </span>
                          </span>
                        }>
                        <Menu.Item key='admin/category'>
                          <Link to='/admin/category'>
                            <i className='icon icon-crypto' />
                            <span>
                              <IntlMessages id='Category' />
                            </span>
                          </Link>
                        </Menu.Item>

                        <Menu.Item key='admin/services'>
                          <Link to='/admin/services'>
                            <i className='icon icon-listing-dbrd' />
                            <span>
                              <IntlMessages id='Service' />
                            </span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                      <SubMenu
                        key='Locations'
                        popupClassName={getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            {" "}
                            <i className='icon icon-dasbhoard' />
                            <span>
                              <IntlMessages id='Location' />
                            </span>
                          </span>
                        }>
                        <Menu.Item key='admin/states'>
                          <Link to='/admin/states'>
                            <i className='icon icon-crypto' />
                            <span>
                              <IntlMessages id='States' />
                            </span>
                          </Link>
                        </Menu.Item>

                        <Menu.Item key='admin/cities'>
                          <Link to='/admin/cities'>
                            <i className='icon icon-listing-dbrd' />
                            <span>
                              <IntlMessages id='Cities' />
                            </span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                      <Menu.Item key='admin/sales'>
                        <Link to='/admin/sales'>
                          <i className='icon icon-auth-screen' />
                          <span>
                            <IntlMessages id='Coins History' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <SubMenu
                        key='Lead Management'
                        popupClassName={getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            {" "}
                            <i className='icon icon-dasbhoard' />
                            <span>
                              <IntlMessages id='Lead Management' />
                            </span>
                          </span>
                        }>
                        <Menu.Item key='admin/leadManagement'>
                          <Link to='/admin/leadManagement'>
                            <i className='icon icon-crypto' />
                            <span>
                              <IntlMessages id='Add New Lead' />
                            </span>
                          </Link>
                        </Menu.Item>

                        <Menu.Item key='admin/leads'>
                          <Link to='/admin/leads'>
                            <i className='icon icon-listing-dbrd' />
                            <span>
                              <IntlMessages id='Leads' />
                            </span>
                          </Link>
                        </Menu.Item>
                      </SubMenu>
                    </>
                  )}

                  {garageList.role != "admin" && (
                    <>
                      <Menu.Item key='admin/dashboard'>
                        <Link to='/admin/dashboard'>
                          <i className='icon icon-dasbhoard' />
                          <span className='cliked'>
                            <IntlMessages id='Dashboard' />
                          </span>
                        </Link>
                      </Menu.Item>
                      {filteredData?.map((item, index) => (
                        <Menu.Item key={`admin/${item.endPoint}`}>
                          <Link to={`/admin/${item.endPoint}`}>
                            <i className={`icon icon-${item.icon}`} />
                            <span className=''>
                              <IntlMessages id={item.value} />
                            </span>
                          </Link>
                        </Menu.Item>
                      ))}
                      {/* <Menu.Item
  key={"admin/vecrep/page" || "admin/vecrep/editpage/:id"}
  className={location.pathname === "/admin/vecrep/page" || location.pathname === "/admin/vecrep/editpage/:id" ? "highlighted" : ""}
>
  <Link to={"/admin/vecrep/page" || "/admin/vecrep/editpage/:id"}>
    <i className="icon icon-calendar" style={{ color: a &&'#fa8c15' }}  />
    <span style={{ color: a &&'#fa8c15' }}>
      <IntlMessages id="Pages" />
    </span>
  </Link>
</Menu.Item>
<Menu.Item key="admin/vecrep/approveGarage">
                    <Link to="/admin/vecrep/approveGarage">
                      <i className="icon icon-dasbhoard" />
                      <span className="">
                        <IntlMessages id="Approve Garage" />
                      </span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="admin/vecrep/ourGarage">
                    <Link to="/admin/vecrep/ourGarage">
                      <i className="icon icon-dasbhoard" />
                      <span className="">
                        <IntlMessages id="Our Garage" />
                      </span>
                    </Link>
                  </Menu.Item>

<Menu.Item
  key={"admin/vecrep/nearme" || "admin/vecrep/editpagenear/:id"}
  className={location.pathname === "/admin/vecrep/nearme" || location.pathname === "/admin/vecrep/editpagenear/:id" ? "highlighted" : ""}
>
  <Link to={"/admin/vecrep/nearme" || "/admin/vecrep/editpagenear/:id"}>
    <i className="icon icon-calendar" style={{ color: b &&'#fa8c15' }}  />
    <span style={{ color: b &&'#fa8c15' }}>
      <IntlMessages id="Near Me" />
    </span>
  </Link>
</Menu.Item> */}
                    </>
                  )}

                  {/* {(garageList?.features || []).map((item, index) => (
                <Menu.Item key={`admin/${item.value}`}>
                  <Link to={`/admin/${item.value}`}>
                    <i className={`icon icon-${item.icon}`} />
                    <span className="cliked">
                      <IntlMessages id={item.value} />
                    </span>
                  </Link>
                </Menu.Item>
              ))} */}

                  {garageList.role === "subadmin" && (
                    <SubMenu
                      key='Subadmins'
                      popupClassName={getNavStyleSubMenuClass(navStyle)}
                      title={
                        <span>
                          {" "}
                          <i className='icon icon-dasbhoard' />
                          <span>
                            <IntlMessages id='SubAdmin Management' />
                          </span>
                        </span>
                      }>
                      <Menu.Item key='admin/subAdminManagement'>
                        <Link to='/admin/subAdminManagement'>
                          <i className='icon icon-auth-screen' />
                          <span>
                            <IntlMessages id='SubAdmin-list' />
                          </span>
                        </Link>
                      </Menu.Item>

                      <Menu.Item key='admin/roles'>
                        <Link to='/admin/roles'>
                          <i className='icon icon-crypto' />
                          <span>
                            <IntlMessages id='Roles' />
                          </span>
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                  )}
                  {/* 
              {garageList.features
                ?.filter(
                  (item) =>
                    item.value?.toLowerCase()?.includes() ===
                    "SubAdminManagement"
                )
                ?.map((item, index) => (
                  <SubMenu
                    key="Subadmins"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        {" "}
                        <i className="icon icon-dasbhoard" />
                        <span>
                          <IntlMessages id={`${item}`} />
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item key="admin/subAdminManagement">
                      <Link to="/admin/subAdminManagement">
                        <i className="icon icon-auth-screen" />
                        <span>
                          <IntlMessages id="SubAdmin-list" />
                        </span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="admin/roles">
                      <Link to="/admin/roles">
                        <i className="icon icon-crypto" />
                        <span>
                          <IntlMessages id="Roles" />
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ))} */}
                  {/* {garageList.features
                ?.filter(
                  (item) =>
                    item.value?.toLowerCase()?.includes() ===
                    "category management"
                )
                ?.map((item, index) => (
                  <SubMenu
                    key="Categories"
                    popupClassName={getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        {" "}
                        <i className="icon icon-dasbhoard" />
                        <span>
                          <IntlMessages id={`${item}`} />
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item key="admin/category">
                      <Link to="/admin/category">
                        <i className="icon icon-crypto" />
                        <span>
                          <IntlMessages id="Category" />
                        </span>
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="admin/services">
                      <Link to="/admin/services">
                        <i className="icon icon-listing-dbrd" />
                        <span>
                          <IntlMessages id="Service" />
                        </span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ))} */}
                </>
              )}

              {tab1 === "2" && (
                <>
                  {garageList?.role === "admin" && (
                    <>
                      <Menu.Item key='admin/vecrep/dashboard'>
                        <Link to='/admin/vecrep/dashboard'>
                          <i className='icon icon-dasbhoard' />
                          <span className='cliked'>
                            <IntlMessages id='Dashboard' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        key={
                          "admin/vecrep/page" ||
                          "admin/vecrep/editpage/:id" ||
                          "admin/vecrep/seoeditpage/:id"
                        }
                        className={
                          location.pathname === "/admin/vecrep/page" ||
                          location.pathname === "/admin/vecrep/editpage/:id" ||
                          location.pathname === "/admin/vecrep/seoeditpage/:id"
                            ? "highlighted"
                            : ""
                        }>
                        <Link
                          to={
                            "/admin/vecrep/page" ||
                            "/admin/vecrep/editpage/:id" ||
                            "/admin/vecrep/seoeditpage/:id"
                          }>
                          <i
                            className='icon icon-calendar'
                            style={{ color: a && "#fa8c15" }}
                          />
                          <span style={{ color: a && "#fa8c15" }}>
                            <IntlMessages id='Pages' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/approveGarage'>
                        <Link to='/admin/vecrep/approveGarage'>
                          <i className='icon icon-dasbhoard' />
                          <span className=''>
                            <IntlMessages id='Approve Garage' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/ourGarage'>
                        <Link to='/admin/vecrep/ourGarage'>
                          <i className='icon icon-dasbhoard' />
                          <span className=''>
                            <IntlMessages id='Our Garage' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        key={
                          "admin/vecrep/nearme" ||
                          "admin/vecrep/editpagenear/:id"
                        }
                        className={
                          location.pathname === "/admin/vecrep/nearme" ||
                          location.pathname === "/admin/vecrep/editpagenear/:id"
                            ? "highlighted"
                            : ""
                        }>
                        <Link
                          to={
                            "/admin/vecrep/nearme" ||
                            "/admin/vecrep/editpagenear/:id"
                          }>
                          <i
                            className='icon icon-calendar'
                            style={{ color: b && "#fa8c15" }}
                          />
                          <span style={{ color: b && "#fa8c15" }}>
                            <IntlMessages id='Near Me' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/mycustomer'>
                        <Link to='/admin/vecrep/mycustomer'>
                          <i className='icon icon-dasbhoard' />
                          <span className=''>
                            <IntlMessages id='My Customer' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/booking'>
                        <Link to='/admin/vecrep/booking'>
                          <i className='icon icon-dasbhoard' />
                          <span className=''>
                            <IntlMessages id='Booking Management' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/leads'>
                        <Link to='/admin/vecrep/leads'>
                          <i className='icon icon-dasbhoard' />
                          <span className=''>
                            <IntlMessages id='Leads' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/servicePage'>
                        <Link to='/admin/vecrep/servicePage'>
                          <i className='icon icon-calendar' />
                          <span className=''>
                            <IntlMessages id='Service Pages' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/users'>
                        <Link to='/admin/vecrep/users'>
                          <i className='icon icon-user' />
                          <span className=''>
                            <IntlMessages id='Users' />
                          </span>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key='admin/vecrep/quotes'>
                        <Link to='/admin/vecrep/quotes'>
                          <i className='icon icon-calendar' />
                          <span className=''>
                            <IntlMessages id='Quotes' />
                          </span>
                        </Link>
                      </Menu.Item>
                    </>
                  )}

                  {garageList.role !== "admin" && (
                    <>
                      <Menu.Item key='admin/vecrep/dashboard'>
                        <Link to='/admin/vecrep/dashboard'>
                          <i className='icon icon-dasbhoard' />
                          <span className='cliked'>
                            <IntlMessages id='Dashboard' />
                          </span>
                        </Link>
                      </Menu.Item>
                      {filteredData?.map((item, index) => (
                        <Menu.Item key={`admin/vecrep/${item.endPoint}`}>
                          <Link to={`/admin/vecrep/${item.endPoint}`}>
                            <i className={`icon icon-${item.icon}`} />
                            <span className=''>
                              <IntlMessages id={item.value} />
                            </span>
                          </Link>
                        </Menu.Item>
                      ))}
                    </>
                  )}
                </>
              )}
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

import React, { useRef, useState } from "react";
import "./Quotes.css";
import { Popover, DatePicker } from "antd";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

const { RangePicker } = DatePicker;

const QuotesForm = ({
  ExportCsv,
  searchInput,
  setSearchInput,
  setSelectedDates,
  fetchQuotesList,
}) => {
  const rangePickerRef = useRef(null);

  const handleClear = () => {
    setSelectedDates([]);
    setSearchInput("");
    if (rangePickerRef.current) {
      rangePickerRef.current.blur(); // Close the picker if open
      rangePickerRef.current.input.value = ""; // Clear input field
    }

    fetchQuotesList();
  };

  const onDateChange = (dates, dateStrings) => {
    const formattedDates = dateStrings?.every((date) => date) // Check if all dates are non-empty
      ? dateStrings.map((date) =>
          moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
        )
      : []; // Set to empty array if any date is empty
    console.log("Selected dates:", dateStrings);
    console.log("Formatted dates (YYYY-MM-DD):", formattedDates);
    setSelectedDates(formattedDates);
    fetchQuotesList(formattedDates);
  };

  return (
    <div>
      <div className='quote-manage-card-header'>
        <form className='quote-manage-form-control'>
          <RangePicker
            ref={rangePickerRef}
            className='min-w-[280px]'
            format='DD-MM-YYYY'
            onChange={onDateChange}
            size='medium'
            allowClear
          />
          <div className='quote-manage-input customer-man1'>
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          {/* <div>
            <Popover content={"Clear Filters"} trigger='hover'>
              <button
                type='button'
                className='customer-manage-form-button'
                onClick={handleClear}>
                <CloseOutlined />
              </button>
            </Popover>
          </div> */}
          <div onClick={ExportCsv}>
            <Popover content={"Export CSV"} trigger='hover'>
              <button type='button' className='quote-manage-form-excel'>
                <TbFileExport />
              </button>
            </Popover>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuotesForm;

import React, { useState } from "react";
import "./Users.css";
import moment from "moment";
import { Avatar, Pagination, Spin } from "antd";
import * as XLSX from "xlsx";
import UsersForm from "./UsersForm";

const UsersTable = ({ garageList, load }) => {
  const [pageSize, setpageSize] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleExportExcel = () => {
    const headers = [
      "S.No",
      "Date",
      "Name",
      "Phone Number",
      "Email",
      "State",
      "City",
      "Gender",
      "Date of Birth",
      "isVerified",
    ];

    // Creating data array for the Excel sheet
    const excelData = [];

    // Adding headers to the data array
    excelData.push(headers);

    // Populating data from the table rows
    paginatedData?.forEach((item, index) => {
      const formattedDate = item?.customer?.date
        ? moment(item?.customer?.date).format("DD-MM-YYYY")
        : "-";
      const rowData = [
        startIndex + index + 1,
        formattedDate,
        item?.customer?.customerName,
        item?.customer?.phoneNumber,
        item?.customer?.email,
        item?.customer?.state,
        item?.customer?.city,
        item?.customer?.email,
        item?.customer?.dateOfBirth
          ? moment(item?.customer?.dateOfBirth).format("DD-MM-YYYY")
          : "-",
        item?.customer?.customerStatus === 1 ? "True" : "False",
      ];
      excelData.push(rowData);
    });

    // Create a workbook
    const wb = XLSX.utils.book_new();
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "users_data.xlsx");
  };

  const filterEmployeesBySearchInput = (quotes, searchInput) => {
    if (!searchInput) return quotes;
    console.log(quotes);
    return quotes.filter(
      (quote) =>
        quote?.customer?.customerName
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase()) ||
        quote?.customer?.phoneNumber?.includes(searchInput) ||
        quote?.customer?.email
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase()) ||
        quote?.customer?.state
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase()) ||
        quote?.customer?.city
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase()) ||
        quote?.customer?.gender
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase())
    );
  };

  const filteredQuotes = filterEmployeesBySearchInput(
    garageList?.filter((quote) => {
      const formattedDate = moment(
        quote?.customer?.date,
        "YYYY-MM-DD"
      ).toDate();
      const start = startDate ? moment(startDate, "YYYY-MM-DD").toDate() : null;
      const end = endDate ? moment(endDate, "YYYY-MM-DD").toDate() : null;

      // If both startDate and endDate are missing, return all data
      if (!start || !end) {
        return true;
      }

      // Apply filtering: Include startDate and endDate in the range
      const fromDateMatch = !start || formattedDate >= start;
      const toDateMatch = !end || formattedDate <= end;

      return fromDateMatch && toDateMatch;
    }),
    searchInput
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = filteredQuotes?.slice(startIndex, endIndex);

  return (
    <>
      <Spin spinning={load}>
        <UsersForm
          ExportCsv={handleExportExcel}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        {!load && (
          <div className='users-manage-table'>
            <table>
              <thead>
                <tr>
                  <th className='th1'>S.No</th>
                  <th className='th2'>Date</th>
                  <th className='th3'>Name</th>
                  <th className='th4'>Phone Number</th>
                  <th className='th5'>Email</th>
                  <th className='th6'>State</th>
                  <th className='th7'>City</th>
                  <th className='th8'>Gender</th>
                  <th className='th9'>Date of Birth</th>
                  <th className='th10'>isVerified</th>
                </tr>
              </thead>

              <tbody>
                {paginatedData?.length === 0 ? (
                  <tr>
                    <td colSpan='13' style={{ textAlign: "center" }}>
                      No Users found.
                    </td>
                  </tr>
                ) : (
                  paginatedData?.map((item, index) => {
                    const words = item?.customer?.customerName?.split(" ");

                    const formattedDate = item?.customer?.date
                      ? moment(item?.customer?.date).format("DD-MM-YYYY")
                      : "-";

                    return (
                      <tr key={item.id} style={{ textAlign: "center" }}>
                        <td>{startIndex + index + 1}</td>
                        <td>{formattedDate}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}>
                            {item?.customer?.customerImage &&
                            item?.customer?.customerImage.startsWith(
                              "https"
                            ) ? (
                              <img src={item?.customer?.customerImage} alt='' />
                            ) : (
                              <Avatar
                                shape='square'
                                size={40}
                                style={{
                                  backgroundColor: "#b11226",
                                  fontWeight: 600,
                                  fontSize: 17,
                                  minHeight: 40,
                                  minWidth: 40,
                                }}>
                                {words.length > 1
                                  ? words[0]?.charAt(0)?.toUpperCase() +
                                    words[1]?.charAt(0)?.toUpperCase()
                                  : words[0]?.charAt(0)?.toUpperCase()}
                              </Avatar>
                            )}
                            {item?.customer?.customerName || "-"}
                          </div>
                        </td>
                        <td>{item?.customer?.phoneNumber || "-"}</td>
                        <td>{item?.customer?.email || "-"}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.customer?.state || "-"}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.customer?.city || "-"}
                        </td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.customer?.gender || "-"}
                        </td>
                        <td>
                          {item?.customer?.dateOfBirth
                            ? moment(item.customer.dateOfBirth).format(
                                "DD-MM-YYYY"
                              )
                            : "-"}
                        </td>
                        <td>
                          {item?.customer?.customerStatus === 1
                            ? "True"
                            : "False"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          simple
          onChange={onChange}
          current={currentPage}
          total={garageList?.length}
          pageSize={pageSize}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default UsersTable;

import React, { useEffect, useState } from "react";
import "./ourGarage.css";
import {
  FaEdit,
  FaLock,
  FaLockOpen,
  FaPencilAlt,
  FaUnlockAlt,
} from "react-icons/fa";
import {
  Avatar,
  Pagination,
  Popconfirm,
  Spin,
  message,
  Row,
  Col,
  Modal,
  Switch,
  Form,
  InputNumber,
  Input,
  Select,
  Button,
} from "antd";
import axios from "axios";
import ApiDomain from "../../api/ApiDomain";
import CategoryForm from "./CategoryForm";
import * as XLSX from "xlsx";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditCategory from "../main/GarageManagement/EditCategory";
import MultipleImageUpload from "../ApproveGarage/ImageUpload";

const CategoryTable = ({ garageList, fetchGarageList, load }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const token = localStorage.getItem("admin");
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [form] = Form.useForm();
  const itemsPerPage = 10;
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    statused: "",
    search: "",
  });
  const [modalXV, setModalXV] = useState(false);
  const [modalXV1, setModalXV1] = useState(false);
  const [modalXV2, setModalXV2] = useState(false);
  const [modalXV3, setModalXV3] = useState(false);
  const [modalXV4, setModalXV4] = useState(false);
  const [modalXV8, setModalXV8] = useState(false);
  const [image, setImages] = useState("");
  const [modalXV5, setModalXV5] = useState(false);
  const [modalXV6, setModalXV6] = useState(false);
  const [analyticsModal, setAnalyticsModal] = useState(false);
  const [selectedSpecializations, setSelectedSpecializations] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState(null);
  const [analyticsLoading, setAnalyticsLoading] = useState(false);

  console.log(selectedGarage);

  const fetchVecrepAnalytics = async () => {
    setAnalyticsLoading(true);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/admin/getVecrepAnalyticData`,
        {
          garageId: selectedGarage ? selectedGarage?._id : "", // 🔹 Body parameter
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // 🔹 Ensures correct request format
          },
        }
      );

      console.log(response);
      if (response.status === 200) {
        console.log(response.data);
        if (response.data.responseCode === 200) {
          console.log(response.data);
          form.setFieldsValue({
            updateCustomerStatus: response.data.data.isVerified,
            jobsCompleted: response.data.data.jobs_completed || "0",
            rating: response.data.data.rating.toString() || "0",
            ratings: response.data.data.rating_count || "0",
            reviews: response.data.data.review_count || "0",
          });
        } else if (response.data.responseCode === 400) {
          message.warning(response.data.message);
        } else if (response.data.responseCode === 404) {
          message.warning(response.data.message);
        } else {
          message.error("Something Went wrong");
        }
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setAnalyticsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedGarage) {
      fetchVecrepAnalytics();
    }
  }, [selectedGarage]);

  const onFinish = async (values) => {
    console.log(values);
    setAnalyticsLoading(true);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/admin/VecrepAnalyticData`,
        {
          isVerified: values?.updateCustomerStatus,
          jobs_completed: values?.jobsCompleted || "0",
          rating: values?.rating || "0",
          rating_count: values?.ratings || "0",
          review_count: values?.reviews || "0",
          garageId: selectedGarage ? selectedGarage?._id : "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // 🔹 Ensures correct request format
          },
        }
      );
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        console.log(response.data);
        if (
          response.data.responseCode === 200 ||
          response.data.responseCode === 201
        ) {
          console.log(response.data);
          message.success(response.data.message);
          setSelectedGarage(null);
          form.resetFields();
          setAnalyticsModal(false);
        } else if (response.data.responseCode === 400) {
          message.warning(response.data.message);
        } else if (response.data.responseCode === 404) {
          message.warning(response.data.message);
        } else {
          message.error("Something Went wrong");
        }
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setAnalyticsLoading(false);
    }
  };

  const handleImageHover = (image) => {
    setSelectedImage(image);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList, currentPage]);

  const applyFilters = () => {
    const filteredList = garageList.filter((item) => {
      const fromDateMatch =
        !filterCriteria.fromDate ||
        new Date(item.registationDate) >= new Date(filterCriteria.fromDate);

      const toDateMatch =
        !filterCriteria.toDate ||
        new Date(item.registationDate) <= new Date(filterCriteria.toDate);

      const statusMatch =
        !filterCriteria.statused ||
        item.accountStatus === filterCriteria.statused;

      const searchMatch =
        !filterCriteria.search ||
        item.garageName
          .toLowerCase()
          .includes(filterCriteria.search.toLowerCase()) ||
        (item.garageOwnerName &&
          item.garageOwnerName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())) ||
        (item.phoneNumber &&
          item.phoneNumber
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())) ||
        (item.vecrepId &&
          item.vecrepId
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())) ||
        (item.email &&
          item.email
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())) ||
        (item.pancardNumber &&
          item.pancardNumber
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())) ||
        (item.gstNumber &&
          item.gstNumber
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())) ||
        (item.businessType &&
          item.businessType
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase()));

      console.log(
        item.vecrepId
          .toLowerCase()
          .includes(filterCriteria.search.toLowerCase())
      );

      return fromDateMatch && toDateMatch && statusMatch && searchMatch;
    });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleExportExcel = () => {
    const formattedData = filteredGarageList?.map((item, index) => {
      const formattedDate = moment(item?.registationDate).format("DD-MM-YYYY");

      // Extracting category names from the list of objects
      const categories = item.specializations
        ? item.specializations.map((category) => category.name).join(", ")
        : "";

      // Extracting service names from the list of objects
      const services = item.services
        ? item.services.map((service) => service.serviceName).join(", ")
        : "";

      // Extracting bank details
      const bankDetails = item.banks
        ? item.banks
            .map((bank) => {
              return `${bank.nameAsPerBankAccount} - ${bank.bankName} (${bank.accountNumber})`;
            })
            .join(", ")
        : "";

      // Extracting social media details
      const socialMediaDetails = item.socialMedias
        ? item.socialMedias
            .map((socialMedia) => {
              return `${socialMedia.socialMediaName} - ${socialMedia.link}`;
            })
            .join(", ")
        : "";

      // Extracting working hours
      const workingHours = item.workingHours
        ? item.workingHours
            .map((workingHour) => {
              return `${workingHour.Day}: ${workingHour.StartTime} - ${workingHour.EndTime}`;
            })
            .join(", ")
        : "";

      return [
        index + 1,
        formattedDate || "-",
        item.vecrepId || "-",
        item.garageName || "-",
        item.garageOwnerName || "-",
        item.email || "-",
        item.phoneNumber || "-",
        item.address || "-",
        item.state || "-",
        item.city || "-",
        item.area || "-",
        item.pancardNumber || "-",
        item.gstNumber || "-",
        item.businessType || "-",
        item.garageType === 1
          ? "Car"
          : item.garageType === 2
          ? "Bike"
          : item.garageType === 3
          ? "Both"
          : "-",
        item.garageReferenceCode || "-",
        categories || "-", // Categories
        services || "-", // Services
        bankDetails || "-", // Bank Details
        socialMediaDetails || "-", // Social Media
        item.urlLink || "-", // URL Link - Not available in the data
        workingHours || "-", // Working Hours
        item.status ? "Active" : "Inactive",
      ];
    });

    // Add header row
    formattedData.unshift([
      "S.No",
      "Register Date",
      "Vecrep Id",
      "Garage Name",
      "Owner Name",
      "Email",
      "Mobile Number",
      "Address",
      "State",
      "City",
      "Area",
      "PAN Number",
      "GST Number",
      "Business Type",
      "Vehicle Type",
      "Refer Code",
      "Categories",
      "Services",
      "Bank Details",
      "Social Media",
      "URL Link",
      "Working Hours",
      "Status",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  const cancelV = () => {
    setSelectedSpecializations(null);
  };
  const cancelServiceOffered = () => {
    setSelectedService(null);
  };

  const seoEdit = (data) => {
    history.push(`/admin/vecrep/garageseoedit/${data._id}`);
  };

  async function confirm(e, item, status) {
    console.log(item, status);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/garageStatusChanging`,
        { garageId: item._id, status: !status },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success("Status Changed Success");
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  function cancel(e) {}

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-approve-approve-modal-overlay")) {
      handleCloseModal();
    }
  };

  const cancelx = () => {
    setModalXV1(false);
    setModalXV2(false);

    setModalXV3(false);

    setModalXV4(false);

    setModalXV5(false);
    setModalXV(false);
    setModalXV6(false);
    setModalXV8(false);
    setAnalyticsModal(false);
    form.resetFields();
    setSelectedGarage(null);
    setSelectedImage(null);
  };
  const modalX = (selectedGarage) => {
    setModalXV(true);

    setSelectedGarage(selectedGarage); // Updated to use visibleRows
  };
  const modalX6 = (selectedGarage) => {
    setImages("");
    setModalXV6(true);

    setSelectedGarage(selectedGarage); // Updated to use visibleRows
  };

  const modalX1 = (selectedGarage) => {
    setModalXV1(true);
    // setGarageId(id)
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };

  const modalX2 = (selectedGarage) => {
    setModalXV2(true);
    // setGarageId(id)
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };
  const modalX3 = (selectedGarage) => {
    setModalXV3(true);
    // setGarageId(id)
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };
  const modalX4 = (selectedGarage) => {
    setModalXV4(true);
    // setGarageId(id)
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };
  const modalX5 = (selectedGarage) => {
    setModalXV5(true);
    // setGarageId(id)
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };
  const modalX8 = (selectedGarage) => {
    setModalXV8(true);
    // setGarageId(id)
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };

  const handleAnalyticsClick = (selectedGarage) => {
    setSelectedGarage(selectedGarage);
    setAnalyticsModal(true);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);
  console.log(visibleRows);
  const handleOpenModal = (index) => {
    console.log(selectedGarage);

    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };
  console.log(selectedGarage);
  return (
    <Spin spinning={load}>
      <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel} />
      {!load && (
        <div className='approve-approve-table' onClick={handleOverlayClick}>
          {filteredGarageList.length === 0 ? (
            <p style={{ textAlign: "center", margin: 30, fontSize: 20 }}>
              No garages to display.
            </p>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th className='th1'>S.No</th>
                    <th className='th2'>Garage Image</th>
                    <th className='th3'>Register Date</th>
                    <th className='th4'>Vecrep Id</th>
                    <th className='th5'>Garage Name</th>
                    <th className='th5'>Owner Name</th>

                    <th className='th6'>Email</th>
                    <th className='th7'>Mobile Number</th>

                    <th className='th5'>Address</th>
                    <th className='th5'>State</th>
                    <th className='th5'>City</th>
                    <th className='th5'>Area</th>
                    <th className='th8'>PAN Number</th>
                    <th className='th9'>GST Number</th>
                    <th className='th5'>Business Type</th>
                    <th className='th5'>Vehicle Type</th>
                    <th className='th5'>Refer Code</th>
                    <th className='th5'>Categories</th>
                    <th className='th12'>View Services</th>
                    <th className='th12'>Images</th>
                    <th className='th12'>Vecrep Analytics</th>
                    <th className='th12'>Bank Details</th>
                    <th className='th12'>Social Media</th>
                    <th className='th12'>URL Link</th>
                    <th className='th12'>Working Hours</th>
                    <th className='th9'>Edit</th>
                    <th className='th9'>SEO Edit</th>
                    <th className='th9'>Status</th>
                    <th className='th9'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredGarageList
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((item, index) => {
                      const words = item.garageName?.split(" ");
                      const formattedDate = moment(
                        item?.registationDate
                      ).format("DD-MM-YYYY");
                      // Extract the first specialization name
                      const firstSpecialization =
                        item.categories[0]?.specializationName || "NA";

                      // Create a string of other specialization names separated by commas
                      const otherSpecializations = item.categories
                        .slice(1)
                        .map((categorie) => categorie.specializationName)
                        .join(", ");
                      const lengthCategories = item?.categories?.length;
                      const lengthService = item?.services?.length;
                      // Extract the first service name
                      const firstService =
                        item.services[0]?.serviceName || "NA";

                      // Create a string of other specialization names separated by commas
                      const otherServices = item.services
                        .slice(1)
                        .map((service) => service.serviceName)
                        .join(", ");
                      return (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className='table-image'>
                              {item.imagePath ? (
                                <img src={item.imagePath} alt='' />
                              ) : (
                                <Avatar
                                  shape='square'
                                  size={40}
                                  style={{
                                    backgroundColor: "#b11226",
                                    fontWeight: 600,
                                    fontSize: 17,
                                  }}>
                                  {words.length > 1
                                    ? words[0]?.charAt(0)?.toUpperCase() +
                                      words[1]?.charAt(0)?.toUpperCase()
                                    : words[0]?.charAt(0)?.toUpperCase()}
                                </Avatar>
                              )}
                            </div>
                          </td>
                          <td>{formattedDate || "-"}</td>
                          <td>{item.vecrepId || "-"}</td>
                          <td>{item.garageName || "-"}</td>
                          <td>{item.garageOwnerName || "-"}</td>
                          <td>{item.email || "-"}</td>
                          <td>{item.phoneNumber || "-"}</td>
                          <td>{item.address || "-"}</td>
                          <td>{item.state || "-"}</td>
                          <td>{item.city || "-"}</td>
                          <td>{item.area || "-"}</td>
                          <td>{item.pancardNumber || "-"}</td>
                          <td>{item.gstNumber || "-"}</td>
                          <td>{item.businessType || "-"}</td>
                          <td>
                            {item.garageType === 1
                              ? "Car"
                              : item.garageType === 2
                              ? "Bike"
                              : item.garageType === 3
                              ? "Both"
                              : "-"}
                          </td>

                          <td onClick={(e) => modalX8(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>
                          <td onClick={(e) => modalX2(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                            <span style={{ paddingRight: 5 }}>
                              {" "}
                              {`(${lengthCategories || "0"})`}
                            </span>
                          </td>
                          <td onClick={(e) => modalX1(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                            <span style={{ paddingRight: 5 }}>
                              {" "}
                              {`(${lengthService || "0"})`}
                            </span>
                          </td>
                          <td onClick={(e) => modalX6(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>
                          <td onClick={(e) => handleAnalyticsClick(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>
                          <td onClick={(e) => modalX(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>
                          <td onClick={(e) => modalX3(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>
                          <td onClick={(e) => modalX4(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>
                          <td onClick={(e) => modalX5(item)}>
                            <span
                              className='badge badge-success'
                              style={{ cursor: "pointer" }}>
                              View
                            </span>
                          </td>

                          <td>
                            <div
                              className='action-cell'
                              style={{ cursor: "pointer" }}>
                              <div
                                className='edit-cell'
                                onClick={() => handleOpenModal(index)}>
                                <FaPencilAlt />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              className='action-cell'
                              style={{ cursor: "pointer" }}>
                              <div
                                className='edit-cell'
                                onClick={() => seoEdit(item)}>
                                <FaPencilAlt />
                              </div>
                            </div>
                          </td>
                          <td>
                            <span
                              className={`badge badge-${
                                item.status ? "success" : "danger"
                              }`}
                              // onClick={() => activeStatus(item)}
                            >
                              {item.status ? "Active" : "InActive"}
                            </span>
                          </td>
                          <td>
                            <Popconfirm
                              title='Are you sure Approve this Garage?'
                              onConfirm={(e) => confirm(e, item, item?.status)}
                              onCancel={cancel}
                              okText='Yes'
                              cancelText='No'>
                              <div className='action-cell'>
                                <div className='edit-cell'>
                                  {item.status ? <FaLockOpen /> : <FaLock />}
                                </div>
                              </div>
                            </Popconfirm>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {selectedSpecializations && (
                  <div className='add-purchase-manage-modal-overlay'>
                    <div className='add-purchase-manage-modal-contents'>
                      <div className='add-purchase-manage-modal-content-header'>
                        <span
                          className='add-purchase-manage-close-button'
                          onClick={cancelV}>
                          &times;
                        </span>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "white" }}>
                            Specialised Services
                          </h3>
                        </div>
                      </div>
                      <div
                        className='add-purchase-manage-modal-forms'
                        style={{
                          width: 500,
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <div>{selectedSpecializations}</div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedService && (
                  <div className='add-purchase-manage-modal-overlay'>
                    <div className='add-purchase-manage-modal-contents'>
                      <div className='add-purchase-manage-modal-content-header'>
                        <span
                          className='add-purchase-manage-close-button'
                          onClick={cancelServiceOffered}>
                          &times;
                        </span>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "white" }}>
                            Services Offered
                          </h3>
                        </div>
                      </div>
                      <div
                        className='add-purchase-manage-modal-forms'
                        style={{
                          width: 500,
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <div>{selectedService}</div>
                      </div>
                    </div>
                  </div>
                )}
              </table>
              <div>
                {modalXV8 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV8}
                    onCancel={cancelx}
                    width={600}>
                    <div className='garage-manage-table'>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                            ReferCode Details
                          </h3>
                        </div>
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th className='th4'>My ReferCode</th>
                            <th className='th4'>Total Referal</th>
                            <th className='th4'>Premium Referal</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              {selectedGarage?.garageReferenceCode || "-"}
                            </td>
                            <td>{selectedGarage?.referalsCount}</td>
                            <td>{selectedGarage?.premiumReferal || "-"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Modal>
                )}
              </div>

              <div>
                {modalXV2 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV2}
                    onCancel={cancelx}
                    width={"auto"}>
                    <div className=''>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3
                            style={{
                              fontWeight: "bold",
                              color: "#b11226",
                              textAlign: "center",
                            }}>
                            Categories
                          </h3>
                        </div>
                      </div>

                      {selectedGarage?.categories?.length > 0 ? (
                        <div
                          style={{
                            width: "1000px",
                            height: "200px",
                            maxHeight: "1000px",
                            overflowY: "scroll",
                          }}>
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: 10,
                            }}>
                            {selectedGarage?.categories?.map((each, index) => (
                              <Col xl={6} lg={6} key={index}>
                                <h5
                                  style={{
                                    border: "1px solid black",
                                    padding: "1px 10px 1px 10px",
                                  }}>
                                  {each.categoryName}
                                </h5>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            color: "black",
                            margin: 10,
                            width: "600px",
                          }}>
                          No categories to display
                        </div>
                      )}
                    </div>
                  </Modal>
                )}
              </div>
              <div>
                {modalXV1 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV1}
                    onCancel={cancelx}
                    width={"auto"}>
                    <div className=''>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3
                            style={{
                              fontWeight: "bold",
                              color: "#b11226",
                              textAlign: "center",
                            }}>
                            Services
                          </h3>
                        </div>
                      </div>

                      {selectedGarage?.services?.length > 0 ? (
                        <div
                          style={{
                            width: "800px",
                            minHeight: "200px",
                            overflowY: "scroll",
                          }}>
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              margin: 10,
                            }}>
                            {selectedGarage?.services?.map((each) => (
                              <Col xl={6} lg={6}>
                                <h5
                                  style={{
                                    border: "1px solid black",
                                    padding: "1px 10px 1px 10px",
                                  }}>
                                  {each.serviceName}
                                </h5>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            color: "black",
                            margin: 10,
                            width: 600,
                          }}>
                          No Serives to display
                        </div>
                      )}
                    </div>
                  </Modal>
                )}
              </div>

              <div>
                {modalXV3 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV3}
                    onCancel={cancelx}
                    width={"auto"}>
                    <div className='approve-approve-table'>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                            Social Media
                          </h3>
                        </div>
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th className='th1'>S.No</th>
                            <th className='th4'>Social Media Name</th>
                            <th className='th4'>Link</th>
                            <th className='th4'>Logo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedGarage?.socialMedias?.length !== 0 ? (
                            selectedGarage?.socialMedias?.map((item, index) => {
                              console.log("Item:", item); // Debugging
                              return (
                                <tr key={item._id}>
                                  <td>{startIndex + index + 1}</td>
                                  <td>{item?.socialMediaName}</td>
                                  <td>
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      target='_blank'
                                      href={`https://${item?.lik}`}>
                                      {item?.link}
                                    </a>
                                  </td>
                                  <td>
                                    <img
                                      style={{ height: 21 }}
                                      src={item?.logo}
                                      alt=''
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan='5'
                                style={{ textAlign: "center", color: "black" }}>
                                No Social Media History.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Modal>
                )}
              </div>

              <div>
                {modalXV4 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV4}
                    onCancel={cancelx}>
                    <div className='add-subscription-manage-modal-contents'>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                            URL
                          </h3>
                        </div>
                      </div>

                      <div>
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: 10,
                          }}>
                          <Col xl={24} lg={24}>
                            <a
                              href={`https://${selectedGarage?.urlLink}`}
                              target='_blank'
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                textAlign: "center",
                              }}>
                              {selectedGarage?.urlLink
                                ? selectedGarage?.urlLink
                                : "-"}
                            </a>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
              <div>
                {modalXV5 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV5}
                    onCancel={cancelx}
                    width={"auto"}>
                    <div className='approve-approve-table'>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                            Working Hours
                          </h3>
                        </div>
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th className='th1'>S.No</th>
                            <th className='th4'>Day</th>
                            <th className='th4'>Day Status</th>
                            <th className='th4'>Start Time</th>
                            <th className='th5'>End Time</th>
                          </tr>
                        </thead>

                        <tbody>
                          {selectedGarage?.workingHours?.length === 0 ? (
                            <tr>
                              <td colSpan='13' style={{ textAlign: "center" }}>
                                No Working Hours.
                              </td>
                            </tr>
                          ) : (
                            selectedGarage?.workingHours?.map((item, index) => {
                              // const words = item?.garageName?.split(" ");
                              console.log(
                                selectedGarage?.workingHours?.length === 0
                              );
                              return (
                                <tr key={item._id}>
                                  <td>{startIndex + index + 1}</td>
                                  <td>{item.Day || "-"}</td>
                                  <td>{item.DayStatus}</td>
                                  <td>{item.StartTime}</td>
                                  <td>{item.EndTime}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Modal>
                )}
              </div>
              <div>
                {modalXV && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV}
                    onCancel={cancelx}
                    width={"auto"}>
                    <div className='approve-approve-table'>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                            Bank Details
                          </h3>
                        </div>
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th className='th1'>S.No</th>
                            <th className='th4'>Holder Name</th>
                            <th className='th4'>Bank Name</th>
                            <th className='th4'>Branch</th>

                            <th className='th5'>IFSC Code</th>
                            <th className='th5'>AC Number</th>
                          </tr>
                        </thead>

                        <tbody>
                          {selectedGarage?.banks?.length === 0 ? (
                            <tr>
                              <td colSpan='13' style={{ textAlign: "center" }}>
                                No Bank Details History.
                              </td>
                            </tr>
                          ) : (
                            selectedGarage?.banks?.map((item, index) => {
                              // const words = item?.garageName?.split(" ");

                              const formattedDate1 = moment(
                                item?.startDate
                              ).format("DD-MM-YYYY");
                              const formattedDate2 = moment(
                                item?.endDate
                              ).format("DD-MM-YYYY");

                              console.log(formattedDate2);
                              return (
                                <tr key={item.id}>
                                  <td>{0 + index + 1}</td>
                                  <td>{item.nameAsPerBankAccount}</td>
                                  <td>{item.bankName}</td>
                                  <td>{item.branch}</td>
                                  <td>{item.ifscCode}</td>

                                  <td>{item.accountNumber}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Modal>
                )}
              </div>

              <div>
                {modalXV6 && (
                  <Modal
                    footer={null}
                    centered
                    visible={modalXV6}
                    onCancel={cancelx}
                    width={1200}>
                    <div className=''>
                      <div className='add-subscription-manage-modal-content-header'>
                        <div>
                          <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                            Images
                          </h3>
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: 10, margin: 10 }}>
                        <div style={{ width: "100%" }}>
                          <MultipleImageUpload
                            selectedGarage={selectedGarage}
                            handleCloseModal={cancelx}
                            fetchGarageList={fetchGarageList}
                            setImagesPreview={setImages}
                          />
                        </div>
                        {/* <div>
                          {image ? (
                            <div
                              style={{
                                border: "1px solid black",
                                height: 270,
                                width: 250,
                              }}
                            >
                              <img
                                src={image}
                                style={{ height: 270, width: 250 }}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px solid black",
                                height: 270,
                                width: 250,
                              }}
                            >
                              <span style={{ padding: 10 }}>
                                No Image Preview
                              </span>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
              <Modal
                footer={null}
                centered
                visible={analyticsModal}
                onCancel={cancelx}>
                <Spin spinning={analyticsLoading}>
                  <Form
                    layout='vertical'
                    form={form}
                    preserve
                    onFinish={onFinish}>
                    <div className='vecep-analytics-container'>
                      <div className='vecep-analytics-actions'>
                        <div className='vecep-analytics-header'>
                          <h1 style={{ margin: 0, padding: 0 }}>
                            Vecep Analytics
                          </h1>
                          <div className='vecep-analytics-switch'>
                            <p style={{ margin: 0, padding: 0 }}>
                              Garage Verified
                            </p>
                            <Form.Item
                              style={{ margin: 0 }}
                              name='updateCustomerStatus'
                              valuePropName='checked' // ✅ Ensures Switch reflects the correct boolean state
                            >
                              <Switch
                                onChange={(checked) => {
                                  form.setFieldsValue({
                                    updateCustomerStatus: checked,
                                  });
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <Form.Item name='rating' label='Rating'>
                          <Select placeholder='Select Rating'>
                            {["0", "1", "2", "3", "4", "5"].map((value) => (
                              <Select.Option key={value} value={value}>
                                {value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name='jobsCompleted' label='Jobs Completed'>
                          <Input
                            type='number'
                            placeholder='Enter Jobs Completed'
                            min={0}
                          />
                        </Form.Item>
                        <Form.Item name='ratings' label='Ratings'>
                          <Input
                            type='number'
                            placeholder='Enter number of Ratings'
                            min={0}
                          />
                        </Form.Item>
                        <Form.Item name='reviews' label='Reviews'>
                          <Input
                            type='number'
                            placeholder='Enter number of Reviews'
                            min={0}
                          />
                        </Form.Item>
                        <footer className='form-footer'>
                          <Button
                            type='default'
                            className='button-min-width'
                            onClick={cancelx}>
                            Back
                          </Button>
                          <Button
                            type='primary'
                            htmlType='submit'
                            className='button-min-width'>
                            Save & Next
                          </Button>
                        </footer>
                      </div>
                    </div>
                  </Form>
                </Spin>
              </Modal>

              <div>
                {isModalVisible && (
                  <EditCategory
                    handleCloseModal={handleCloseModal}
                    selectedGarage={selectedGarage}
                    fetchGarageList={fetchGarageList} // Pass the selected garage details as props
                    open={isModalVisible}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
      <Pagination
        simple
        onChange={onChange}
        total={filteredGarageList.length}
        style={{ marginTop: 10, float: "right" }}
        current={currentPage}
      />
    </Spin>
  );
};

export default CategoryTable;

import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Spin,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./Garage.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";
import LocationSearch from "./Location";

const { Option } = Select;

const AddCategory = ({ handleCloseModal, fetchGarageList, open }) => {
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [load, setLoad] = useState(false);
  const [autoSuggestions, setAutoSuggestions] = useState([]);

  const [workingHours, setWorkingHours] = useState([
    { day: "Monday", slots: [], disabled: false },
    { day: "Tuesday", slots: [], disabled: false },
    { day: "Wednesday", slots: [], disabled: false },
    { day: "Thursday", slots: [], disabled: false },
    { day: "Friday", slots: [], disabled: false },
    { day: "Saturday", slots: [], disabled: false },
    { day: "Sunday", slots: [], disabled: false },
  ]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [slot, setSlots] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const [lat, setLatitude] = useState("");
  const [long, setLongitude] = useState("");
  const { Option } = Select;
  const [addressed, setAddressed] = useState("");
  const [parentAddress, setParentAddress] = useState("");
  const [area, setArea] = useState("");

  const handleChangeCar = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoadingCar(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://api.vecrep.com/uploadImage",
        formData
      );
      const imageUrl = response.data.imagePath1;
      setImageUrlCar(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoadingCar(false);
    }
  };

  const handleDayToggle = (day) => {
    setWorkingHours((prev) =>
      prev.map((item) =>
        item.day === day ? { ...item, disabled: !item.disabled } : item
      )
    );
  };

  const handleInputChange = (selectedCategories) => {
    // Initialize an empty array to store category objects with both ID and name
    const categoriesWithId = [];
    // selectedCategories will be an array of objects containing both ID and name
    selectedCategories.forEach((category) => {
      // Create an object with both ID and name properties
      const categoryObj = {
        categoryId: category,
      };
      // Push the category object to the categoriesWithId array
      categoriesWithId.push(categoryObj);
    });
    // Log the array containing objects with both ID and name properties
    setCategoriesData(categoriesWithId);
    // Now you can use the categoriesWithId array for further processing
  };

  const token = localStorage.getItem("admin");
  const fetchCategoryList = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/CategoryAdminList`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setGarageList(response?.data?.categories);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchServiceList = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/ServiceNameAdminList`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setServiceList(response?.data?.services);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceList();
  }, []);
  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfStates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data.data);
      setState(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchCities = async (id) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data);
      setCity(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceStates();
  }, []);

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then((formValues) => {
        const selectedState = state.find(
          (state) => state._id === formValues.state
        );
        const stateName = selectedState ? selectedState.stateName : ""; // Extract state name
        const submittedData = workingHours.map((dayObj) => ({
          day: dayObj.day,
          slots: dayObj.slots,
          checked: !dayObj.disabled,
        }));

        const requestData = {
          ...formValues,
          state: stateName, // Replace state ID with state name

          imagePath: imageUrlCar,
          address: addressed,
          area: area ? area : values.area,
          latitude: lat,
          longitude: long,
          availableTimes: submittedData,
          startTime: selectedStartTime,
          endTime: selectedEndTime,
          categories: categoriesData, // Make sure to define selectedCategories state
        };
        console.log(requestData);

        setLoad(true);

        axios
          .post(`${ApiDomain.Domain}/garage_Registation`, requestData)
          .then((response) => {
            console.log("API Response:", response);
            console.log(response?.data?.responseCode);
            if (response.data?.responseCode === 200) {
              fetchGarageList();
              history.push("/admin/ApproveGarage");
              fetchGarageList();

              handleCloseModal();
              message.success(response?.data?.message);
              setLoad(false);
            } else if (response.data?.responseCode === 400) {
              message.warning(response.data?.message);
              setLoad(false);
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
          });
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    // You can perform additional actions based on the selected value
  };
  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedVehicleType, garageList]);

  const handleVehicleTypeChange = (e) => {
    setSelectedVehicleType(e.target.value);
    console.log(e.target.value);
    filterGarageSuggestions();
  };
  const filterGarageSuggestions = () => {
    const suggestions = garageList.filter(
      (garage) => garage.vehicleType == selectedVehicleType
    );
    console.log(suggestions);
    setFilteredGarageList(suggestions);
  };
  const handletimeChange = (e) => {
    setWorkingHours([
      { day: "Monday", slots: [], disabled: false },
      { day: "Tuesday", slots: [], disabled: false },
      { day: "Wednesday", slots: [], disabled: false },
      { day: "Thursday", slots: [], disabled: false },
      { day: "Friday", slots: [], disabled: false },
      { day: "Saturday", slots: [], disabled: false },
      { day: "Sunday", slots: [], disabled: false },
    ]);

    if (e.target.value === 2) {
      setSlots(false);
    } else {
      setSlots(true);
    }
  };
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const validatePhoneNumber = (_, value) => {
    if (value && value.replace(/\D/g, "").length !== 10) {
      return Promise.reject("Phone number must be 10 digits");
    }
    return Promise.resolve();
  };
  const handleAddressChange = (autocompletedText, address) => {
    setParentAddress(autocompletedText);
  };
  const [selectedState, setSelectedState] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleStateChange = (value) => {
    setSelectedState(false);
    // Fetch cities for the selected state from the API
    // Replace the API call with your actual API endpoint
    fetchCities(value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  return (
    <>
      <Modal
        visible={open}
        centered
        onCancel={handleCloseModal}
        footer={null}
        width={800} // Adjust the width as per your requirement
      >
        <div className="">
          <div className="">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3
                  style={{
                    fontWeight: "bold",
                    color: "#b11226",
                    textAlign: "center",
                  }}
                >
                  Add Garage
                </h3>
              </div>
            </div>
            <div
              className="add-garage-manage-modal-forms"
              style={{ maxHeight: "500px", overflowY: "scroll" }}
            >
              <Spin spinning={load}>
                <div className="parent-select">
                  <Form
                    form={form}
                    layout="vertical"
                    style={{}}
                    onFinish={handleSubmit}
                  >
                    <div>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              name="garageImage"
                              label="Garage Logo : "
                              rules={[
                                {
                                  // required: true,
                                  message: "Please upload Garage Image",
                                },
                              ]}
                            >
                              <div className="upload">
                                <button type="button" className="btn-warning">
                                  <CloudUploadOutlined /> Upload File
                                  <input
                                    type="file"
                                    onChange={handleChangeCar}
                                  />
                                </button>
                              </div>
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}></Col>
                        </div>
                      </Row>

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Garage Name : "
                              name="garageName"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  required: true,
                                  message: "Please input your Garage Name!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your Garage Name"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item label="Owner Name : " name="ownerName">
                              <Input
                                placeholder="Enter Your Owner Name"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Contact No : "
                              name="phoneNumber"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  required: true,
                                  message: "Please input your Phone number!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Mobile Number"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>

                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Email : "
                              name="email"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your Email"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <LocationSearch
                              address={addressed}
                              updateValue={setAddressed}
                              setLatitude={setLatitude}
                              setLongitude={setLongitude}
                              onLocationChange={handleAddressChange}
                              area={setArea}
                              initialValue={parentAddress}
                            />
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Area : "
                              name="area"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Input placeholder="Enter Your Area" />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item label="State : " name="state">
                              <Select
                                placeholder="Select State"
                                style={{ width: "100%" }}
                                onChange={handleStateChange}
                              >
                                {/* Assume state is an array of state objects */}
                                {state?.map((suggestion) => (
                                  <Option
                                    key={suggestion._id}
                                    value={suggestion._id}
                                  >
                                    {suggestion.stateName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>

                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="City : "
                              name="city"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select City"
                                style={{ width: "100%" }}
                                onChange={handleCityChange}
                                disabled={selectedState}
                              >
                                {city?.map((city) => (
                                  <Option
                                    key={city._id} // Combine id and cityName for uniqueness
                                    value={city.cityName}
                                  >
                                    {city.cityName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                        {/* <div style={{ width: "50%" }}>
                   <Col xl={24} lg={24}>
                     <Form.Item
                       label="Area : "
                       name="area"
                       initialValue={area}
                       rules={[
                         {
                           type: "text",
                           message: "The input is not valid!",
                         },
                       ]}
                     >
                       <Input 
                      
                         placeholder="Enter Your Area"
                         style={{  }}
                       />
                     </Form.Item>
                   </Col>
                 </div> */}
                      </Row>

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="PAN Number : "
                              name="pancardNumber"
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  message: "Please input your Pan Card number!",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your PAN Card Number"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }} className="">
                          <Col xl={24} lg={24}>
                            <Form.Item
                              name="businessType"
                              label="Business Type "
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Business Type",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{}}
                                placeholder="Select Type"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="Workshops">
                                  Workshops/Garages/Modification
                                </Option>
                                <Option value="Accessories">Accessories</Option>
                                <Option value="Washing">
                                  Washing/Detailing
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item label="GST Number : " name="gstNumber">
                              <Input
                                placeholder="Enter Your gst Card Number"
                                style={{}}
                              />
                            </Form.Item>
                          </Col>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item label="URL: " name="urlLink">
                              <Input placeholder="Enter Your URL" style={{}} />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Open and Close time : "
                              name="time"
                            >
                              <Radio.Group
                                name="radiogroup"
                                onChange={handletimeChange}
                              >
                                <Radio value={1}>Manual Time</Radio>
                                <Radio value={2}>24*7</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                      {slot && (
                        <>
                          <h5
                            style={{
                              color: "inherit",
                              paddingLeft: "8px",
                              paddingBottom: "8px",
                              fontWeight: "bold",
                            }}
                          >
                            Working Hours:
                          </h5>
                          {workingHours.map((dayObj) => (
                            <Row
                              key={dayObj.day}
                              style={{
                                paddingBottom: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              <Col xl={24} lg={24}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "150px",
                                      textAlign: "right",
                                      paddingRight: "20px",
                                    }}
                                  >
                                    <label style={{ textAlign: "center" }}>
                                      {dayObj.day}
                                    </label>
                                  </div>
                                  <div>
                                    <TimePicker
                                      use12Hours
                                      disabled={dayObj.disabled}
                                      onChange={(time, timeString) =>
                                        setSelectedStartTime(timeString[0])
                                      }
                                      format="hh:mm:ss A"
                                      style={{ marginRight: "10px" }}
                                    />
                                    <TimePicker
                                      use12Hours
                                      disabled={dayObj.disabled}
                                      onChange={(time, timeString) =>
                                        setSelectedEndTime(timeString[1])
                                      }
                                      format="hh:mm:ss A"
                                    />
                                    <Checkbox
                                      onChange={() =>
                                        handleDayToggle(dayObj.day)
                                      }
                                      style={{ marginLeft: "10px" }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}

                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Vehicle Type : "
                              name="garageType"
                              required
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                {
                                  required: true,
                                  message: "Please input your vehicle Type!",
                                },
                              ]}
                            >
                              <Radio.Group
                                name="radiogroup"
                                onChange={handleVehicleTypeChange}
                              >
                                <Radio value={1}>4W</Radio>
                                <Radio value={2}>2W</Radio>
                                <Radio value={3}>Both</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>

                      <Row
                        style={{
                          display: "flex",

                          flexDirection: "row",

                          width: "100%",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Categories : "
                              name="categories"
                              required
                              rules={[
                                {
                                  type: "text",
                                  message: "The input is not valid!",
                                },
                                // {
                                //   required: true,
                                //   message: "Please Select Categories!",
                                // },
                              ]}
                            >
                              <div style={{ minHeight: 30 }}>
                                <Select
                                  mode="multiple"
                                  placeholder="Select Categories"
                                  style={{ height: "40px" }}
                                  onChange={handleInputChange}
                                >
                                  {filteredGarageList?.map((suggestion) => (
                                    <Option
                                      style={{ padding: 10 }}
                                      key={suggestion._id}
                                      value={suggestion._id} // Pass both ID and name in an object
                                    >
                                      {suggestion.categoryName}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>

                      <Row
                        style={{
                          display: "flex",

                          flexDirection: "row",

                          width: "100%",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <Col xl={24} lg={24}>
                            <Form.Item
                              label="Garage Description : "
                              name="aboutGarage"
                            >
                              <TextArea
                                rows={3}
                                placeholder="Enter Description"
                              />
                            </Form.Item>
                          </Col>
                        </div>
                      </Row>
                    </div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Col
                          xl={24}
                          lg={24}
                          style={{ display: "flex", gap: 16 }}
                        >
                          <Form.Item name="promotion">
                            <Checkbox
                              style={{}}
                              onChange={(e) => setIsGSTExist(e.target.checked)}
                            />
                          </Form.Item>
                          <label style={{ paddingTop: 10 }}>
                            Receive Relevant Offers and Promotional Notification
                          </label>
                        </Col>
                      </div>

                      <div style={{ width: "50%" }}>
                        <Col xl={24} lg={24}></Col>
                      </div>
                    </Row>

                    <div className="add-garage-manage-popup-footer">
                      <button
                        className="cancel-button"
                        onClick={handleCancel}
                        style={{ marginRight: 8 }}
                      >
                        Cancel
                      </button>

                      <button className="submit-button" htmlType="submit">
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCategory;

import React, { useState } from "react";
import "./Users.css";
import { Popover } from "antd";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined } from "@ant-design/icons";

const UsersForm = ({
  ExportCsv,
  searchInput,
  setSearchInput,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setStartDate(e.target.value);
    setDisableToDate(false);
    const minimumToDateValue = selectedDate ? selectedDate : null;
    setEndDate("");
    setMinimumToDate(minimumToDateValue);
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setSearchInput("");
    setDisableToDate(true);
    setMinimumToDate(null);
  };

  return (
    <div>
      <div className='quote-manage-card-header'>
        <form className='quote-manage-form-control'>
          <div className='quote-manage-input customer-man'>
            <input
              type='date'
              id='fromdate'
              name='fromdate'
              onClick={(e) => e.target.showPicker()}
              value={startDate}
              onChange={handleDateChange}
              placeholder='From Date'
              className='quote-manage-input-cal'
            />
          </div>
          <div className='quote-manage-input customer-man'>
            <input
              type='date'
              id='todate'
              name='todate'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={minimumToDate}
              onClick={(e) => e.target.showPicker()}
              placeholder='To Date'
              className='quote-manage-input-cal'
              disabled={disableToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div>

          <div className='quote-manage-input customer-man1'>
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          <div>
            <Popover content={"Clear Filters"} trigger='hover'>
              <button
                type='button'
                className='customer-manage-form-button'
                onClick={handleClear}>
                <CloseOutlined />
              </button>
            </Popover>
          </div>
          <div onClick={ExportCsv}>
            <Popover content={"Export CSV"} trigger='hover'>
              <button type='button' className='quote-manage-form-excel'>
                <TbFileExport />
              </button>
            </Popover>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsersForm;

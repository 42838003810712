import React, { useState } from "react";
import "./Quotes.css";
import moment from "moment";
import { Avatar, message, Pagination, Spin } from "antd";
import * as XLSX from "xlsx";
import QuotesForm from "./QuotesForm";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";

const QuotesTable = ({
  quotesList,
  load,
  setLoad,
  fetchQuotesList,
  currentPage,
  setCurrentPage,
  total,
  pageSize,
  setSelectedDates,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const token = localStorage.getItem("admin");

  const handleExportExcel = async () => {
    console.log("Export CSV function cluck");
    try {
      setLoad(true);
      const payload = {
        page: 1,
        limit: total,
        garageId: "",
        customerId: "",
        source: "",
        startDate: "",
        endDate: "",
      };
      const response = await axios.post(
        `${ApiDomain.Domain}/vecrep/getQuotes`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      if (data.responseCode === 200) {
        const quotesData = data?.data || [];
        const headers = [
          "S.No",
          "Date",
          "Name",
          "Phone Number",
          "Email",
          "Text",
          "Page Source",
          "Garage Name",
        ];
        const excelData = [];
        excelData.push(headers);
        quotesData.forEach((item, index) => {
          const formattedDate = moment(item?.date).format("DD-MM-YYYY");
          const rowData = [
            index + 1,
            formattedDate,
            item?.name || "N/A",
            item?.phoneNumber || "N/A",
            item?.email || "N/A",
            item?.note || "N/A",
            item?.source || "N/A",
            item?.garageId?.garageName || "N/A",
          ];
          excelData.push(rowData);
        });
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(excelData);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "quotes_data.xlsx");
      } else if (data?.responseCode === 400) {
        message.error(data?.message);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
      message.error("Failed to fetch data");
    } finally {
      setLoad(false);
    }
  };

  console.log(quotesList);

  const filteredQuotes = quotesList?.filter((quote) => {
    // If searchInput exists, filter based on the input
    if (searchInput) {
      return (
        quote?.name?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        quote?.phoneNumber?.includes(searchInput) ||
        quote?.email?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        quote?.garageName?.toLowerCase()?.includes(searchInput?.toLowerCase())
      );
    }
    // If searchInput is empty, include all quotes (including those with null values)
    return true;
  });

  return (
    <>
      <Spin spinning={load}>
        <QuotesForm
          ExportCsv={handleExportExcel}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setSelectedDates={setSelectedDates}
          fetchQuotesList={fetchQuotesList}
        />
        {!load && (
          <div className='quotes-manage-table'>
            <table>
              <thead>
                <tr>
                  <th className='th1'>S.No</th>
                  <th className='th2'>Date</th>
                  <th className='th3'>Name</th>
                  <th className='th4'>Phone Number</th>
                  <th className='th5'>Email</th>
                  <th className='th6'>Text</th>
                  <th className='th7'>Page Source</th>
                  <th className='th8'>Garage Name</th>
                </tr>
              </thead>

              <tbody>
                {filteredQuotes?.length === 0 ? (
                  <tr>
                    <td colSpan='13' style={{ textAlign: "center" }}>
                      No Quotes found.
                    </td>
                  </tr>
                ) : (
                  filteredQuotes?.map((item, index) => {
                    const words = item?.name ? item.name.split(" ") : [];
                    const formattedDate = moment(item?.date).format(
                      "DD-MM-YYYY"
                    );
                    return (
                      <tr key={item.id} style={{ textAlign: "center" }}>
                        <td>{index + 1}</td>
                        <td>{formattedDate}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}>
                            {item?.customerImage &&
                            item?.customerImage.startsWith("https") ? (
                              <img src={item?.customerImage} alt='' />
                            ) : (
                              <Avatar
                                shape='square'
                                size={40}
                                style={{
                                  backgroundColor: "#b11226",
                                  fontWeight: 600,
                                  fontSize: 17,
                                  minHeight: 40,
                                  minWidth: 40,
                                }}>
                                {words.length > 1
                                  ? words[0]?.charAt(0)?.toUpperCase() +
                                    words[1]?.charAt(0)?.toUpperCase()
                                  : words[0]?.charAt(0)?.toUpperCase() || "-"}
                              </Avatar>
                            )}
                            {item?.name || "-"}
                          </div>
                        </td>
                        <td>{item?.phoneNumber || "-"}</td>
                        <td>{item?.email || "-"}</td>
                        <td>{item?.note || "-"}</td>
                        <td>{item?.source || "-"}</td>
                        <td>{item?.garageId?.garageName || "-"}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          simple
          onChange={(page, pageSize) => {
            console.log(page, pageSize);
            setCurrentPage(page);
            // setPageSize(pageSize);
          }}
          current={currentPage}
          total={total}
          pageSize={pageSize}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default QuotesTable;

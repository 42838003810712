import React, { useState, useEffect } from "react";
import "./Users.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";
import UsersTable from "./UsersTable";
import { message } from "antd";

const Users = () => {
  const [customerList, setcustomerList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);

  const fetchstart = () => {
    setLoad(true);
  };
  const fetchsuccess = () => {
    setLoad(false);
  };

  const fetchQuotesList = async () => {
    try {
      fetchstart();
      const response = await axios.get(
        `${ApiDomain.Domain}/vecrep/vecrepCustomers`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;
      if (data.responseCode === 200) {
        setcustomerList(data?.data || []);
      } else if (data?.responseCode === 400) {
        message.error(data?.message);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      fetchsuccess();
    }
  };

  useEffect(() => {
    fetchQuotesList();
  }, []);

  return (
    <>
      <div className='user-manage-container'>
        <div className='user-manage-content'>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <div className='customer-manage-header'>
              <h3 className='customer-manage-head'>Users</h3>
            </div>
            <div>
              <ReloadOutlined
                style={{
                  marginLeft: "10px",
                  border: "1px solid black",
                  cursor: "pointer",
                  color: "black",
                  padding: 7,
                  borderRadius: 5,
                }}
                onClick={() => fetchQuotesList()}
              />
            </div>
          </div>
          <div className='customer-manage-card'>
            <UsersTable
              garageList={customerList}
              load={load}
              fetchQuotesList={fetchQuotesList}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;

import React, { useState, useEffect } from "react";
import "./Quotes.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";
import QuotesTable from "./QuotesTable";

const Quotes = () => {
  const [customerList, setcustomerList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);

  const fetchstart = () => {
    setLoad(true);
  };
  const fetchsuccess = () => {
    setLoad(false);
  };

  const fetchQuotesList = async (date = []) => {
    try {
      fetchstart();
      const payload = {
        page: currentPage,
        limit: pageSize,
        garageId: "",
        customerId: "",
        source: "",
        startDate: date?.length > 0 ? date[0] : "",
        endDate: date?.length > 0 ? date[1] : "",
      };
      const response = await axios.post(
        `${ApiDomain.Domain}/vecrep/getQuotes`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;
      console.log(data);
      if (data.responseCode === 200) {
        setTotal(data?.totalQuotes);
        setcustomerList(data?.data || []);
      } else if (data?.responseCode === 400) {
        message.error(data?.message);
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      fetchsuccess();
    }
  };

  useEffect(() => {
    fetchQuotesList(selectedDates);
  }, [currentPage]);

  return (
    <>
      <div className='quote-manage-container'>
        <div className='quote-manage-content'>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <div className='customer-manage-header'>
              <h3 className='customer-manage-head'>Quotes</h3>
            </div>
            <div>
              <ReloadOutlined
                style={{
                  marginLeft: "10px",
                  border: "1px solid black",
                  cursor: "pointer",
                  color: "black",
                  padding: 7,
                  borderRadius: 5,
                }}
                onClick={() => fetchQuotesList()}
              />
            </div>
          </div>
          <div className='customer-manage-card'>
            <QuotesTable
              quotesList={customerList}
              load={load}
              setLoad={setLoad}
              fetchQuotesList={fetchQuotesList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              total={total}
              setSelectedDates={setSelectedDates}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotes;
